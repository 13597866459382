export const STATSIG_DEFAULT_VALUE = "unallocated";
export const STATSIG_UID_COOKIE_NAME = "userID";
export const STATSIG_COOKIE_MAX_AGE = 60;
export const STATSIG_COOKIE_PREFIX = "ss_";
export const STATSIG_GATE_COOKIE_PREFIX = "ss_gate_";
export const STATSIG_OVERRIDE_COOKIE_PREFIX = "ss_override_";
export const STATSIG_IS_ACTIVE_EXPERIMENT_NAME = "ss_is_active_experiment";
export const USER_IP_COOKIE_NAME = "user_ip";
export const COOKIE_MAX_AGE = 86400; // 24 hours in seconds

// Temporal constants (in seconds)
export const ONE_HOUR = 60 * 60;
export const ONE_YEAR = ONE_HOUR * 24 * 365;

export const CLICKSERVER_INIT_DELAY = 2000;

export const ADCONFIG_DEFAULT: AdConfig = {
  platform: ["desktop", "mobile"],
  provider: "ATD",
  breakpoint: ["h3", "TopRecipeCard", "TopJokeCard"],
  // Which ATD INR to start at/what number starting from 129 for Ezoic
  firstIndex: 1,
  limit: 12,
  between: 2,
  containerSuffix: "AP",
};
